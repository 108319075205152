<template>
    <div>
        <Loading v-if="processing" />
        <div v-if="!mediaUrls">
            <p class="text-center p-5">
                This <span v-if="user_name"> supporter </span> <span v-else>artist</span> did not add any songs to their
                profile
            </p>
        </div>
        <div class="grid grid-cols-1 mx-4 -my-1 " v-else>
            <div class="mb-4" v-for="media in mediaUrls" :key="media.id">
                <div class=" bleez-text" v-if="media.artist">
                    <router-link :to="{
                        name: 'ProfileWithSlug', params: {
                            slug: media.
                                artist.slug
                        }
                    }"> {{ media.artist.member_name }}
                    </router-link>
                </div>
                <div class="
            flex
            lg:m-3
            md:m-6
            m-2
            md:block-height
            block-mobile-height
            w-auto
          ">
                    <div class="relative md:w-1/3 w-2/5">
                        <img src="../../../assets/image/dark-y-t-play.png" alt=""
                            class="absolute top-0 bottom-0 right-0 left-0 m-auto w-12 cursor-pointer"
                            @click="playFullscreen(media.id, media.public_id)" />
                        <div :style="'background:url(https://img.youtube.com/vi/' + getUrlId(media.url) + '/mqdefault.jpg); background-position: center; background-size: cover;'"
                            :class="{
                                'rounded-l-3xl h-full w-full': playingVideo !== media.id,
                                'fixed h-screen w-screen top-0 left-0 z-10':
                                    playingVideo === media.id,
                            }"></div>
                        <iframe :id="media.id" :src="media.url" type="text/html" frameborder="0" :class="{
                            'rounded-l-3xl h-full w-full hidden': playingVideo !== media.id,
                            'fixed h-screen w-screen top-0 left-0 z-10':
                                playingVideo === media.id,
                        }" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen; "
                            allowfullscreen>
                        </iframe>
                        <div @click="closePlayer(media)" :class="{
                            'rounded-full text-xl rapbg  text-white z-10 px-2 py-1': true,
                            hidden: playingVideo !== media.id,
                            'fixed top-2 right-2': playingVideo === media.id,
                        }">
                            Close
                        </div>
                        <div class="absolute inset-0" @click="playFullscreen(media, media.public_id)"></div>

                    </div>
                    <div class="
              md:w-2/3
              w-3/5
              p-2 
              flex flex-col
              justify-center
              border border-4 border-gray-400
              rounded-r-3xl
            ">
                        <div class="py-5">
                            <p class="lg:text-sm md:text-2xl mb-3 block-text">
                                {{ media.title }}
                            </p>
                            <p class="
              truncate 
              xl:text-md
              lg:text-sm
              text-sm
            text-gray-400
              ">
                                {{ media.description }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="
            xl:text-md
            lg:text-xl
            text-center
            bleez-text
            md:text-2xl
            my-1
            text-md
          " v-if="currentProfileUserId == user.public_id && user.type == 'artist'">
                    <button :disabled="processing" class="text-red-400 my-4 font-bold"
                        @click="removeArtistMedia(media.public_id)">
                        Remove from profile
                    </button>
                </div>
                <div v-else-if="user && user.public_id">
                    <div class="grid grid-cols-4">
                        <div class="col-span-3">
                            <div class="
                  xl:text-md
                  lg:text-xl
                  bleez-text
                  md:text-2xl
                  my-2
                  text-md
                ">
                                <button class="my-4  font-bold" @click="addSongToProfile(media.public_id)" v-if="user.profile_songs &&
                                    !user.profile_songs.includes(media.public_id) &&
                                    user.type == 'user'
                                    " :disabled="processing">
                                    Add to profile
                                </button>
                                <button class="text-red-500 my-4 font-bold" @click="removeSongFromProfile(media.public_id)"
                                    v-else-if="user.profile_songs &&
                                        user.profile_songs.includes(media.public_id) &&
                                        user.type == 'user'
                                        " :disabled="processing">
                                    Remove from profile
                                </button>
                            </div>
                        </div>
                        <div class="my-5 text-right"
                            v-if="userState == currentProfileState && $route.name == 'ProfileWithSlug'">
                            <button class="
                  text-white
                  px-5
                  py-1
                  
                  rapbg
                  rounded-lg
                  font-black
                " @click="$emit('vote', media.public_id)" v-show="!isVoted" :disabled="voteProcessing">
                                Vote
                            </button>
                            <button class="
                  text-white
                  px-5
                  py-1
                  
                  bg-gray-400
                  rounded-lg
                  font-black
                  cursor-not-allowed
                " v-show="isVoted">
                                Vote
                            </button>
                        </div>
                    </div>
                </div>
                <div class="border-b-2 mx-3 mt-6"></div>
            </div>
        </div>
        <!-- user delete media  pop up -->
        <transition name="fade">
            <div class="
          absolute
          shadow-lg
          text-center
          top-0
          right-0
          left-0
          bottom-0
          m-auto
          w-full
          h-screen
        " v-if="deleteUserMediaPopup">
                <div class="
            fixed
            top-10
            shadow-lg
            text-center
            right-0
            left-0
            m-auto
            bg-white
            w-8/12
            h-44
            p-2
            rounded-xl
          ">
                    <p>Are you sure do you want to delete profile media ?</p>
                    <div class="h-3/4 relative">
                        <button class="
                w-20
                absolute
                bottom-2
                left-2
                bg-gray-500
                hover:bg-gray-700
                text-white
                font-bold
                py-2
                px-4
                rounded
              " @click="deleteUserMediaPopup = false">
                            Cancel
                        </button>
                        <button class="
                w-20
                absolute
                bottom-2
                right-2
                bg-blue-500
                hover:bg-blue-700
                text-white
                font-bold
                py-2
                px-4
                rounded
              " @click="removeSongFromProfile(media.public_id)">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <!-- user Remove media  pop up -->
        <transition name="fade">
            <div class="
          absolute
          shadow-lg
          text-center
          top-0
          right-0
          left-0
          bottom-0
          m-auto
          w-full
          h-screen
        " v-if="deleteRemoveMediaPopup">
                <div class="
            fixed
            top-10
            shadow-lg
            text-center
            right-0
            left-0
            m-auto
            bg-white
            w-8/12
            h-44
            p-2
            rounded-xl
          ">
                    <p>Are you sure do you want to delete profile media ?</p>
                    <div class="h-3/4 relative">
                        <button class="
                w-20
                absolute
                bottom-2
                left-2
                bg-gray-500
                hover:bg-gray-700
                text-white
                font-bold
                py-2
                px-4
                rounded
              " @click="deleteRemoveMediaPopup = false">
                            Cancel
                        </button>
                        <button class="
                w-20
                absolute
                bottom-2
                right-2
                bg-blue-500
                hover:bg-blue-700
                text-white
                font-bold
                py-2
                px-4
                rounded
              " @click="removeMedia(media.public_id)">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <!-- artist delete profile media  pop up -->
        <transition name="fade">
            <div class="
          absolute
          shadow-lg
          text-center
          top-0
          right-0
          left-0
          bottom-0
          m-auto
          w-full
          h-screen
        " v-if="deleteArtistMediaPopup">
                <div class="
            fixed
            top-10
            shadow-lg
            text-center
            right-0
            left-0
            m-auto
            bg-white
            w-8/12
            h-44
            p-2
            rounded-xl
          ">
                    <p>Are you sure do you want to delete media ?</p>
                    <div class="h-3/4 relative">
                        <button class="
                w-20
                absolute
                bottom-2
                left-2
                bg-gray-500
                hover:bg-gray-700
                text-white
                font-bold
                py-2
                px-4
                rounded
              " @click="deleteArtistMediaPopup = false">
                            Cancel
                        </button>
                        <button class="
                w-20
                absolute
                bottom-2
                right-2
                bg-blue-500
                hover:bg-blue-700
                text-white
                font-bold
                py-2
                px-4
                rounded
              " @click="removeArtistMedia(media.public_id)">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { mapGetters } from "vuex";
import Loading from '../../Shared/Loading'

export default {
    name: "AddToProfile",
    components: {
        Loading,
    },
    props: [
        "currentProfileUserId",
        "chnagePlays",
        "vote",
        "isVoted",
        "userState",
        "currentProfileState",
        "voteProcessing",
        "user_name"
    ],
    emits: ["changePlays"],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            // mediaUrls: [],
            deleteArtistMediaPopup: false,
            deleteUserMediaPopup: false,
            deleteRemoveMediaPopup: false,
            playingVideo: null,
            processing: false,
        };
    },
    created() {
        this.init();
    },
    computed: {
        ...mapGetters({ user: "User/user" }),
        mediaUrls() {
            return this.$store.getters["Profile/mediaUrls"][this.$route.params.slug];
        },
    },
    methods: {
        init() {
            API.get("show-media/" + this.currentProfileUserId).then((response) => {
                this.$store.commit("Profile/SET_MEDIA", {
                    mediaUrls: response.mediaUrls,
                    slug: this.$route.params.slug,
                });
                // this.mediaUrls = response.mediaUrls;
            });
        },
        playFullscreen(media, songPublicId) {
            var id = media.id;
            API.post("increment", {
                user_public_id: this.user.public_id ? this.user.public_id : 0,
                artist_media_public_id: songPublicId,
            }).then((response) => {
                // console.log(response.plays)
                this.$emit("changePlays", response.plays);
            });

            var vid = document.getElementById(id);
            var videoSRC = media.url;
            if (videoSRC.indexOf("autoplay=1") === -1) {
                if (videoSRC.indexOf("?") === -1) {
                    videoSRC +=
                        "?autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer";
                } else {
                    videoSRC +=
                        "&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer";
                }
            }
            vid.src = videoSRC;

            this.playingVideo = id;

            // https://www.youtube.com/embed/LrJbrydDf2s?enablejsapi=1&version=3&playerapiid=ytplayer

            // alert(vid.src);
            // if (~youtubevideo.src.indexOf("?")) youtubevideo.src += "&autoplay=1";
            // else youtubevideo.src += "?autoplay=1";

            // vid.style.position = "fixed";
            // vid.style.width = "100vw";
            // vid.style.height = "100vh";
            // vid.style.top = "0px";
            // vid.style.left = "0px";
            // document.getElementById("exit").style.display = "inline";
        },

        convertEmbedUrl(youtubeUrl) {
            youtubeUrl = youtubeUrl.replace("watch?v=", "embed/");
            // youtubeUrl = youtubeUrl + '?modestbranding=1';
            // console.log(youtubeUrl);
            return youtubeUrl;
        },

        addSongToProfile(songPublicId) {
            this.processing = true;
            API.post("add-profile-media", {
                artist_media_public_id: songPublicId,
            })
                .then((response) => {
                    this.processing = false;
                    var updateUser = this.user;
                    updateUser.profile_songs.push(songPublicId);
                    this.$store.commit("User/SET_USER", updateUser);
                    this.toast.success(response.message);
                })
                .catch(() => {
                    this.processing = false;
                });
        },

        removeSongFromProfile(songPublicId) {
            this.processing = true;
            API.post("remove-profile-media", {
                artist_media_public_id: songPublicId,
            }).then((response) => {
                // this.deleteUserMediaPopup = false;
                this.toast.success(response.message);
                var updateUser = this.user;
                updateUser.profile_songs = updateUser.profile_songs.filter(
                    (e) => e !== songPublicId
                );
                this.$store.commit("User/SET_USER", updateUser);
                this.processing = false;

            })
                .catch(() => {
                    this.processing = false;
                });
        },

        removeArtistMedia(songPublicId) {
            this.processing = true;
            API.post("remove-media", { artist_media_public_id: songPublicId })
                .then((response) => {
                    this.deleteArtistMediaPopup = false;
                    this.toast.success(response.message);
                    this.mediaUrls = this.mediaUrls.filter(
                        (e) => e.public_id !== songPublicId
                    );
                    var updateUser = this.user;
                    updateUser.profile_songs = updateUser.profile_songs.filter(
                        (e) => e === songPublicId
                    );
                    this.$store.commit("User/SET_USER", updateUser);
                    this.init();
                    this.processing = false;
                })
                .catch(() => {
                    this.deleteArtistMediaPopup = false;
                    this.processing = false;
                });
        },

        removeMedia(public_id) {
            this.remove_public_id = public_id;
            this.deleteUserMediaPopup = true;
            this.processing = false;
        },
        closePlayer(media) {
            var vid = document.getElementById(media.id);
            vid.src = media.url;
            this.playingVideo = null;
        },

        getUrlId(url) {
            var regExp = /^.*((youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match && match[7].length == 11) ? match[7] : false;

            // console.log(url);
            //  https://www.youtube.com/watch?v=SUXJjjU_6-Q
            // var videoid = url.match(/(?:https?:/{ 2}) ? (?: w{ 3}.)?youtu(?: be)?.(?: com | be)(?: /watch?v=|/)([^ s &] +) /);
            // if (videoid != null) {
            //   console.log("video id = ", videoid[1]);
            // } else {
            //   console.log("The youtube url is not valid.");
            // }

        },
    },
};
</script>
